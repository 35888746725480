import countryAreas from '@/helpers/countries';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PLACE_PHOTO_BASE_URL = 'https://maps.googleapis.com/maps/api/place/photo';

export const getPlaceIndustry = (place) => {
    if (place?.types?.includes('car_dealer')) {
        return 1;
    }

    if (place?.types?.includes('restaurant')) {
        return 5;
    }

    return null;
};

export const getPlacePhoto = (place) => {
    if (place.photos?.[0]?.photo_reference) {
        return `${PLACE_PHOTO_BASE_URL}?maxwidth=600&photoreference=${place.photos[0].photo_reference}&key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
    }

    return null;
}

export const formatAddressComponents = (place) => {
    const componentsObject = {};
    for (let i = 0; i < place.address_components.length; i++) {
        let component = place.address_components[i];
        componentsObject[component.types[0]] = component;
    }
    return componentsObject;
};

const formatGooglePlace = (place) => {

    const addrComp = formatAddressComponents(place);

    // Lookup local country for validation
    const countryCode = addrComp?.country?.short_name;
    const country = countryAreas.find(country => country.abbreviation === countryCode);

    let phoneNumber = '';
    if (place.international_phone_number) {
        phoneNumber = parsePhoneNumberFromString(place.international_phone_number, countryCode).formatNational();
    }

    // Create an array with the street parts so it can be joined downstream
    // we have to do this because we can't always rely on both
    const streetParts = [];
    const streetNumber = addrComp?.street_number?.short_name;
    if (streetNumber) {
        streetParts.push(streetNumber);
    }
    const route = addrComp?.route.short_name;
    if (route) {
        streetParts.push(route);
    }

    // If the country has configuration allow it to override the default admin area to use
    let googleAdminArea = 'administrative_area_level_1';
    if (country) {
        googleAdminArea = country.googleAdminArea;
    }

    let website = '';
    if (place.website) {
        const websiteUrl = new URL(place.website);
        website = websiteUrl.origin + websiteUrl.pathname;
    }

    return {
        placeId: place.place_id ?? '',
        vertical: getPlaceIndustry(place),
        name: place.name ?? '',
        website,
        phone: phoneNumber,
        address: {
            street: streetParts.join(' '),
            city: addrComp.locality?.long_name ?? '',
            administrativeArea: addrComp[googleAdminArea]?.short_name ?? '',
            postalCode: addrComp.postal_code?.short_name ?? '',
            country: countryCode,
            formatted: place.formatted_address ?? '',
        },
        coordinates: {
            lat: place.geometry?.location?.lat,
            lng: place.geometry?.location?.lng
        },
        photo: getPlacePhoto(place),
        timezone_id:place.timezone_id ?? null
    };
};

export default formatGooglePlace;