<template>
    <v-autocomplete
        ref="select"
        v-model="place"
        :items="places"
        item-text="name"
        item-value="id"
        return-object
        :menu-props="{
            contentClass: 'dealer-menu',
            maxHeight: 290
        }"
        :loading="loading ? 'primary' : false"
        :rules="combinedRules"
        class="dealerSelect dealer-select styled-select"
        append-icon="arrow_drop_down"
        light
        color="grey lighten-2"
        :label="label"
        :placeholder="placeholder"
        hide-no-data
        no-filter
        @input="onSelection"
        @keyup="search"
        @click="onFocus">
        <template #item="{ item }">
            <v-list-tile-content>
                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                <v-list-tile-sub-title class="grey--text text--lighten-2">
                    {{ item.label }}
                </v-list-tile-sub-title>
            </v-list-tile-content>
        </template>
        <template #no-data>
            <v-list-tile>
                <v-list-tile-title>
                    {{ (loading) ? 'Loading...' : 'No places found' }}
                </v-list-tile-title>
            </v-list-tile>
        </template>
    </v-autocomplete>
</template>

<script>
import formatGooglePlace from '@/helpers/formatGooglePlace';
import { autocompleteRules } from '@/helpers/validationRules';
import { get, debounce } from 'lodash';
import countryAreas from '@/helpers/countries';
import { mapState } from 'vuex';

export default {
    props: {
        rules: {
            type: Array,
            default: () => {
                return [];
            }
        },
        label: {
            type: String,
            default: 'Search for a business'
        },
        placeholder: {
            type: String,
            default: 'Enter business name here...'
        },
        useFacebookGeocoder: {
            type: Boolean,
            default: false
        },
        returnId: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            places: [],
            place: '',
            loading: false
        };
    },
    computed: {
        ...mapState({
            userLocation: (state) => state.user.location,
            dealerTimeZones :(state)=>state.dealer.timezones,
        }),
        combinedRules() {
            return [
                ...this.rules,
                ...autocompleteRules
            ];
        }
    },
    methods: {
        reset() {
            this.place = '';
        },
        onFocus() {
            setTimeout(() => this.$refs.select.$refs.input.select(), 200);
        },
        async onSelection(placeSearchResult) {

            if (this.returnId) {
                return this.$emit('input', placeSearchResult.id);
            }

            const place = await this.getPlace(placeSearchResult.id);

            const { lat, lng } = place.coordinates;
            //Google Timezone API uses seconds rather thant milliseconds.
            const timestamp = new Date().getTime() / 1000;
            const timezoneId = await this.getTimezoneId(lat, lng, timestamp);
            const dealerTimeZone = this.dealerTimeZones.find(timezone=>timezone.google_id === timezoneId);

            if(dealerTimeZone) {
                //To use internal Dealer timezone ID
                place.timezone_id = dealerTimeZone.id;
            }
            const country = countryAreas.find(country => {
                return country.abbreviation === place.address.country;
            });

            if (!country) {
                return this.$emit(
                    'error',
                    `The country where the business you selected is located (${place.address.country}) is currently not supported by BuyerBridge.`
                );
            }

            // If we're not using the Facebook geocoder just return the place
            if (!this.useFacebookGeocoder) {
                return this.$emit('input', place);
            }

            // Check the formatted address against Facebook's geocoding service Wego Here
            // we do this so we don't get errors when building out ads using the address
            const facebookLocation = await this.getFacebookGeocode(place.address.formatted);

            // Update the address with data from Facebook that is known to cause issues
            place.address = this.updateAddressWithFacebookLocation(facebookLocation, place.address);
            place.coordinates =  {
                lat: get(facebookLocation, 'position.lat'),
                lng: get(facebookLocation, 'position.lng')
            };

            this.$emit('input', place);
        },
        formatAutocompleteResult(place) {
            return {
                id: get(place, 'place_id', null),
                name: get(place, 'structured_formatting.main_text', null),
                label: get(place, 'structured_formatting.secondary_text', null)
            };
        },
        updateAddressWithFacebookLocation(location, address) {

            // Override only those fields that we have issues with
            address.street = get(location, 'address.formattedStreet') || address.street;
            address.city = get(location, 'address.city') || address.city;
            address.postalCode = get(location, 'address.postalCode') || address.postalCode;

            return address;
        },
        async getFacebookGeocode(address) {
            try {
                this.loading = true;

                const data = await this.$apiRepository.getFacebookLocation(address);
                return data;

            } catch(error) {
                console.error(error);
                this.$emit(
                    'error',
                    'There was an error geocoding this location in Facebook'
                );
            } finally {
                this.loading = false;
            }
        },
        async getPlace(placeId) {
            try {
                this.loading = true;
                const response = await this.$apiRepository.getGooglePlace(placeId);

                if (!response.data.status == 'OK') {
                    throw 'Google returned bad status code';
                }

                // Return for external use
                const place = response.data.result;

                return formatGooglePlace(place);

            } catch(error) {
                console.error(error);
                this.$emit(
                    'error',
                    'There was an error retrieving this location from Google'
                );
            } finally {
                this.loading = false;
            }
        },
        async getTimezoneId(lat,long, timestamp) {
            if(!lat && !long && !timestamp) return ;
           const timezoneResponse = await this.$http.get(`/google/maps/timezone/json?location=${lat},${long}&timestamp=${timestamp}`);
            return timezoneResponse.data.timeZoneId;

        },
        search: debounce(async function(e) {
            try {
                this.loading = true;

                const { value } = e.target;

                if (!value.length) {
                    // Reset the page
                    this.places = [];
                    this.$emit('input', null);
                    return;
                }

                // Don't search until we have 3 characters
                if (value.length < 3) {
                    return;
                }

                const data = {
                    input: value,
                    types: 'establishment',
                };

                // Add the user's location to make the results more accurate
                if (this.userLocation) {
                    data.location = `${this.userLocation.latitude},${this.userLocation.longitude}`;
                }

                const response = await this.$http.get('/google/maps/place/autocomplete/json', data);

                this.places = response.data.predictions.map(this.formatAutocompleteResult.bind(this));
            } catch (error) {
                console.error(error);
                this.$emit(
                    'error',
                    'There was an error trying to run this search on Google'
                );
            } finally {
                this.loading = false;
            }
        }, 250)
    }
};
</script>

<style lang="scss">
.dealer-menu {
    .v-list > div {
        padding: 8px 0;
    }
}
</style>
